import React, { PureComponent } from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Layout from '../components/Layout'
import { QRCode } from "react-qr-svg";
import './QrPage.css';
import domtoimage from 'dom-to-image';


//Palete color
//https://coolors.co/00c2bd-faf0ca-f4d35e-ee964b-f95738

// Export Template for use in CMS preview
//export const QrTemplate = ({ title, subtitle, featuredImage, body }) => {
export class QrTemplate extends PureComponent {

  // console.log('queryString');
  // console.log(queryString);
  state = {
    param: "",
    value: "",
    level: "L",
    bgColor: "#FFFFFF",
    fgColor: "#000000",
  }

  constructor(props) {
    super(props)
  }

  updateValue = (e) => this.setState({ value: e.target.value });
  updateLevel = (e) => this.setState({ level: e.target.value });
  updateBgColor = (e) => this.setState({ bgColor: e.target.value });
  updateFgColor = (e) => this.setState({ fgColor: e.target.value });
  handleClick = (e) => {
    e.preventDefault()

    const { param } = this.state;

    var node = document.getElementById('qr-code');

    domtoimage.toJpeg(node/*, { height: 400, width: 400 }*/)
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = `qr-${param}.jpg`;
        link.href = dataUrl;
        link.click();
      }).catch(function (error) {
        console.error('', error);
      });

  }

  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const param = urlParams.get('param')
    this.setState({ param })

    this.setState({
      value: `https://tumenudigitalqr.com/${param}`
    })
  }

  render() {

    const { title, subtitle, featuredImage } = this.props
    const name = this.state.param ? this.state.param.replace('-', ' ') : ''
    const nameCapitalized = name.charAt(0).toUpperCase() + name.slice(1)

    const {
      state,
      updateLevel,
      updateBgColor,
      updateFgColor,
      handleClick
    } = this;

    return (

      <main className="Home" >

        <PageHeader
          large
          title={title}
          subtitle={subtitle}
          backgroundImage={featuredImage}
          home={true}
        />

        {
          name !== '' &&
          <section className="section">
            <div className="container">

              <h2>{nameCapitalized}</h2>

              <div className="row">

                <div className="col-4">
                  <form className="form">

                    <div className="pure-g">
                      <div className="pure-u-1">
                        <label htmlFor="value">URL</label>
                        <a href={`${state.value}`}>{`${state.value}`}</a>
                      </div>
                      <div className="pure-u-1-3">
                        <label htmlFor="level">Level</label>
                        <select id="level" className="custom-select" onChange={updateLevel}>
                          <option value="L">L</option>
                          <option value="M">M</option>
                          <option value="Q">Q</option>
                          <option value="H">H</option>
                        </select>
                      </div>
                      <div className="pure-u-1-3">
                        <label htmlFor="fgColor">Foregroud</label>
                        <input
                          id="fgColor"
                          onChange={updateFgColor}
                          type="color"
                          value={state.fgColor}
                        />
                      </div>
                      <div className="pure-u-1-3">
                        <label htmlFor="bgColor">Background</label>
                        <input
                          id="bgColor"
                          onChange={updateBgColor}
                          type="color"
                          value={state.bgColor}
                        />
                      </div>

                      <div className="pure-u-3">
                        <label htmlFor="fgColor"></label>
                        <button className="Button" onClick={handleClick}>Descargar</button>
                      </div>
                    </div>

                  </form>

                </div>
                <div className="col-8" >
                  <div id="qr-code" style={{ textAlign: "center", backgroundColor: "#fff", maxWidth: "400px" }}>
                    <QRCode
                      bgColor={state.bgColor}
                      fgColor={state.fgColor}
                      level={state.level}
                      style={{ width: '100%' }}
                      value={state.value}
                    />
                  </div>
                </div>


              </div>

            </div>
          </section>

        }

      </main >
    )
  }
}

// Export Default HomePage for front-end
const QrPage = () => {
  return (
    <Layout meta={false} >
      <QrTemplate />
    </Layout >
  )
}


export default QrPage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query QrPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta,
      html
      frontmatter {
        title
        subtitle
        featuredImage
      }
    }
  }
`
